import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';

import { Link, useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

const BlogIndex = ({ data, location }) => {
    const { t } = useTranslation();
    const { i18n } = useI18next();
    const siteTitle = data.site.siteMetadata?.title || `Blog | RG Softwares`;
    const postsData = data.allMarkdownRemark.nodes;
    const language = i18n.language === 'en' ? 'English' : 'Français';
    const posts = postsData.filter((elem) => {
        console.log(elem);
        if (elem.language === language) return elem;
    });

    if (posts.length === 0) {
        return (
            <Layout location={location} title={siteTitle}>
                <Seo title="Blog | RG Softwares" />
                <p>{t('noPosts')}</p>
            </Layout>
        );
    }

    return (
        <Layout location={location} title={siteTitle}>
            <Seo title="Blog | RG Softwares" />
            <div className="bg-gray p-10">
                <h1>RGS Blog</h1>
                <h2>{t('title')}</h2>
                <ol className="grid grid-flow-row grid-cols-1 sm:grid-cols-3 grid-rows-3 gap-4">
                    {posts.map((post) => {
                        const title = post.frontmatter.title || post.fields.slug;

                        return (
                            <li key={post.fields.slug}>
                                <article className="post-list-item" itemScope itemType="http://schema.org/Article">
                                    <header>
                                        <h2 className="text-xl font-bold">
                                            <Link to={post.fields.slug} itemProp="url">
                                                <span itemProp="headline">{title}</span>
                                            </Link>
                                        </h2>
                                        <small>{post.frontmatter.date}</small>
                                    </header>
                                    <section>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: post.frontmatter.description || post.excerpt,
                                            }}
                                            itemProp="description"
                                        />
                                    </section>
                                </article>
                            </li>
                        );
                    })}
                </ol>
            </div>
        </Layout>
    );
};

export default BlogIndex;

export const pageQuery = graphql`
    query ($language: String!) {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "blogs" } } }
            sort: { fields: [frontmatter___date], order: DESC }
        ) {
            nodes {
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    date(formatString: "MMMM DD, YYYY")
                    title
                    description
                }
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
